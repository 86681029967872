<template>
<div class="forgetPassword page">
  <Headers title="忘记密码"></Headers>
  <div class="scroll">
    <van-form @submit="submit">
      <div class="item" v-for="(item,index) in form_list" :key="index">
        <img :src="item.icon" class="icon" alt="">
        <div class="flexCenter">
          <van-field
              v-model="item.value"
              name=""
              label=""
              :placeholder="item.placeholder"
              :type="item.type"
          />
          <div v-if="item.key == 'img_code'" class="img_code">
<!--            <SIdentify :identifyCode="identifyCode" ></SIdentify>-->
            <img :src="verifyImg" @click="refreshVerify()" alt="">
          </div>
          <div v-if="item.key == 'sms_code'" class="sendCode">
            <sendPhoneCode ref="sendPhoneCodeRef" @sendSmsCode="sendSmsCode"></sendPhoneCode>
          </div>
        </div>
      </div>
      <div style="margin: 30px 0;">
        <van-button round block type="info" native-type="submit" color="linear-gradient(180deg,#9eb0ff,#4969ff)" @click="submit()">提交</van-button>
      </div>
    </van-form>

  </div>
</div>
</template>

<script>
import Headers from "@/components/headers.vue";
import SIdentify from "@/components/SIdentify.vue"
import sendPhoneCode from "@/components/sendPhoneCode.vue";
import {Toast} from "vant";
import axios from "axios";

export default {
  name: "forgetPassword",
  components: {
    sendPhoneCode,
    Headers,
    SIdentify
  },
  data() {
    return {
      identifyCode: '0452',   // 图形验证码随机数
      verifyImg: 'http://172.98.60.152/Login/verify',
      form_list: [
        {
          icon: require('/static/img/login_phone.png'),
          type: 'number',
          key: 'mobile',
          placeholder: '请输入您的手机号',
          value: ''
        },
        {
          icon: require('/static/img/login_code2.png'),
          type: 'text',
          key: 'img_code',
          placeholder: '图形验证码',
          value: ''
        },
        {
          icon: require('/static/img/login_code3.png'),
          type: 'number',
          key: 'sms_code',
          placeholder: '请输入验证码',
          value: ''
        },
        {
          icon: require('/static/img/login_pw.png'),
          type: 'password',
          key: 'login_pwd',
          placeholder: '请输入密码',
          value: ''
        },
        {
          icon: require('/static/img/login_pw.png'),
          type: 'password',
          key: 'confirm_password',
          placeholder: '请确认密码',
          value: ''
        }
      ],

    }
  },
  mounted() {
  },
  methods: {
    makeCode(l=4) {
      for (let i = 0; i < l; i++) {
        this.identifyCode += this.randomNum(1, 9);
      }
      console.log(this.identifyCode);
    },
    refreshVerify(){
      this.verifyImg= 'http://172.98.60.152/Login/verify?v='+Math.random()
    },
    randomNum(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },
    sendSmsCode(){
      if (this.form_list[0].value == '') {
        this.$toast('请输入手机号');
        return false;
      }
      if (this.form_list[1].value == '') {
        this.$toast('请输入图形验证');
        return false;
      }
      $api.password_send_sms_codes({
        verify: this.form_list[1].value,
        mobile: this.form_list[0].value
      }).then(()=> {
          this.$refs.sendPhoneCodeRef[0].setInternal()
      }).catch(err => {

      })

    },
    submit(){
      for (var i = 0; i <this.form_list.length; i++) {
        let item = this.form_list[i];
        if (item.value == "") {
          this.$toast(item.key == 'img_code' ?"请输入" +item.placeholder : item.placeholder);
          return false;
        }
        if (this.form_list[3].value != this.form_list[4].value) {
          this.$toast('两次输入密码不一样');
          return false;
        }
      }
      $api.setpsw({
        mobile: this.form_list[0].value,
        sms_code: this.form_list[2].value,
        login_pwd: this.form_list[3].value,
        confirm_password: this.form_list[4].value,
      }).then(() => {
        Toast("修改成功")
        setTimeout(()=> {
          this.$router.back()
        }, 1000)
      })
    }
  }
}
</script>

<style lang="scss">
.forgetPassword {
  .scroll {
    padding: 50px 33px 33px;
    .item {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #ebebeb;
      height: 60px;
      .sendCode {
        flex-shrink: 0;
        color: #5085ff;
        font-size: 14px;
      }
      .img_code {
        width: 64px;
        height: 25px;
        flex-shrink: 0;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .icon {
        width: 23px;
        height: 24px;
        margin-right: 5px;
      }
    }
  }
}
</style>